let headerAuth = `Bearer ${localStorage.getItem("token") ? localStorage.getItem("token") : null}` 

let headers = localStorage.getItem("token") ? {
  "Authorization": headerAuth,
  "Accept": "application/json",
  "Content-Type": "application/json"
} : {
  "Accept": "application/json",
  "Content-Type": "application/json"
}

let headersUpload = localStorage.getItem("token") ? {
  "Authorization": headerAuth,
  "Accept": "application/json",
} : {
  "Accept": "application/json",
}

let Config = {
  base: "https://prog.empreinte-digitale.re",
  url: "https://prog.empreinte-digitale.re/restricted",
  auth: "https://prog.empreinte-digitale.re/auth",
  headers: headers,
  headersUpload: headersUpload
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  Config = {
    base: "http://localhost:1323",
    url: "http://localhost:1323/restricted",
    auth: "http://localhost:1323/auth",
    headers: headers,
    headersUpload: headersUpload
  }
}

export {Config}