import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/css/argon-dashboard-react.css";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import AdminLayout from "./Containers/Admin";
import AuthLayout from "./Containers/Auth";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/login" render={props => <AuthLayout {...props} />} />
      <Route path="/" render={props => <AdminLayout {...props} />} />      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
