import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	getAdd,
	submitAdd,
	changeField,
} from "../../Utils/Req";

import {
	Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input, Container,
	Row,
	Col,
	FormText,
} from "reactstrap";

import Header from "../../Components/Headers/Header";

class CustomersAdd extends Component {

	state = {
		data: {},
		fields: {},

		alert: {}
	}

	componentDidMount() {
		getAdd("customers").then(async (response) => {
			const resp = await response;
			this.setState({
				data: resp,
				fields: resp.customer
			});
		})

	}

	handleSubmit = (e) => {
		e.preventDefault()
		const valid = document.getElementById("from-customers").reportValidity()
		if (valid) {
			const ctrl = {
				url: "customers",
				field: "customer"
			}
			submitAdd(ctrl, this.state.fields).then(async (response) => {
				const resp = await response;
				this.setState({
					data: resp,
					fields: resp.customer,
					alert: resp.alert
				});
			})
		}
	}

	handleChangeField = (e) => {
		let fields = this.state.fields
		return this.setState({ fields: changeField(e, fields) });
	}

	render() {
		let fields = this.state.fields
		let data = this.state.data

		return (
			<>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Clients</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Link
												color="warning"
												className="btn btn-warning btn-sm"
												to={`/customers`}
												size="sm"
											>
												Retour
                      </Link>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form autoComplete="off" id="from-customers" method="post">
										<h6 className="heading-small text-muted mb-4">
											Ajouter un client
                    </h6>
										<div className="pl-lg-4">
											<Row>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-company"
														>
															Nom de l'entreprise
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.company ? fields.company : ""}
															name={`company`}
															className="form-control-alternative"
															id="input-company"
															type="text"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Nom du contact
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.name ? fields.name : ""}
															name={`name`}
															className="form-control-alternative"
															id="input-name"
															type="text"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-phone"
														>
															Téléphone
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.phone ? `0${fields.phone}` : ""}
															name={`phone`}
															className="form-control-alternative"
															id="input-phone"
															type="tel"
															pattern="^(?:0)[1-9](?:\d\d){4}$"
															required={false}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-email"
														>
															Email
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.email ? fields.email : ""}
															name={`email`}
															className="form-control-alternative"
															id="input-email"
															type="text"
															required={true}
														/>
														<FormText>Séparé par des virgules (,)</FormText>
													</FormGroup>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col className="mt-3">
													{data.alert ?
														<Alert color={data.alert.type}>
															{data.alert.message}
														</Alert> : null
													}
												</Col>
												<Col className="text-right" xs="4">
													<Button
														disabled={Object.keys(fields).length ? false : true}
														color="success"
														type="submit"
														onClick={(e) => this.handleSubmit(e)}
														size="lg"
													>
														Sauvegarder
													</Button>
												</Col>
											</Row>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default CustomersAdd;