import UsersIndex from "../Screens/Users/index";
import UsersAdd from "../Screens/Users/add";
import UsersEdit from "../Screens/Users/edit";
import UsersLogin from "../Screens/Users/login";
const UsersRoutes = [  
	{
		path: "/users",
		name: "Utilisateurs",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: UsersIndex,
		layout: "admin",
		sidebar: true
	},
	{
		path: "/users/add",
		name: "Users Add",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: UsersAdd,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/users/edit/:id",
		name: "Users Edit",
		exact: false,
		icon: "ni ni-folder-17 text-primary",
		component: UsersEdit,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/login",
		name: "users Login",
		exact: false,
		icon: "ni ni-folder-17 text-primary",
		component: UsersLogin,
		layout: "auth",
		sidebar: false
	}
	]

export default UsersRoutes