import React, { Component }  from "react";
import { Link } from "react-router-dom";
import {
	getIndex,dataSelect2,arraySelect2,getEdit, 
	submitEdit, 
	changeField,
} from "../../Utils/Req";

import {
	InputGroup,Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,Container,
	Row,
	Col,
	FormText,} from "reactstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import Header from "../../Components/Headers/Header";
import 'moment/locale/fr'
class CampaignsEdit extends Component  {

  state = {
		data: {},
		fields: {},
		customers: {},
	
		alert: {}
	}
	
	componentDidMount() {
    getEdit("campaigns", this.props.match.params.id).then(async (response) => {
      const resp = await response;
      this.setState({
        data: resp,
        fields: resp.campaign
      });
		})
		getIndex("customers", false).then(async (response) => {
		const resp = await response;
		this.setState({
			customers: resp.customers
		});
	})
	
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const valid = document.getElementById('from-campaigns').reportValidity()
    if(valid) {
			const ctrl = {
				url: "campaigns",
				field: "campaign"
			} 
      submitEdit(ctrl, this.state.fields, this.props.match.params.id).then(async (response) => {
				const resp = await response;
				this.setState({ 
					data: resp,
					fields: resp.campaign,
					alert: resp.alert
				});
      })
    }
  }

  handleChangeField = (e) => {
    let fields = this.state.fields
    return this.setState({ fields: changeField(e, fields) });
  }

  render() {
		let fields = this.state.fields
		let data = this.state.data
		let	customers = this.state.customers
	
    return (
      Object.keys(fields).length ? <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Campaigns</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link
                        color="warning"
                        className="btn btn-warning btn-sm"
                        to={ `/campaigns` }
                        size="sm"
                      >
                        Retour
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
									<Form autoComplete="off" id="from-campaigns" method="post">
										<h6 className="heading-small text-muted mb-4">
											Edit campaign
										</h6>
										<div className="pl-lg-4">
											<Row>												
												
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Name
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.name ? fields.name : ""}
															name={ `name` }
															className="form-control-alternative"
															id="input-name"
															type="text"
															required={ true }
														/>
													</FormGroup>
												</Col>
												
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-date_start"
														>
															Date de début
														</label>
														<InputGroup className="input-group-alternative">
															<ReactDatetime
																closeOnSelect={true}
																timeFormat={false}
																onChange={(e) => this.handleChangeField({value: e, type: "text", name: "date_start"})}
																inputProps={ {
																	name: "date_start",
																	required: false 
																} }
																dateFormat={"YYYY-MM-DDTHH:mm:ssZ"}
																value={fields.date_start ?  moment(fields.date_start).format("YYYY") === "0001" ? null : moment(fields.date_start) : null}
															/>
														</InputGroup>
													</FormGroup>
												</Col>
												
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-date_end"
														>
															Date_end
														</label>
														<InputGroup className="input-group-alternative">
															<ReactDatetime
																closeOnSelect={true}
																timeFormat={false}
																onChange={(e) => this.handleChangeField({value: e, type: "text", name: "date_end"})}
																inputProps={ {
																	name: "date_end",
																	required: false 
																} }
																dateFormat={"YYYY-MM-DDTHH:mm:ssZ"}
																value={fields.date_end ?  moment(fields.date_end).format("YYYY") === "0001" ? null : moment(fields.date_end) : null}
															/>
														</InputGroup>
													</FormGroup>
												</Col>
												
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-customer_id"
														>
														customer
														</label>															
														{ customers.length ?
															<Select2
																name={ `customer_id` }
																onSelect={(e) => this.handleChangeField(e.target)}
																onUnselect={(e) => this.handleChangeField(e.target)}
																defaultValue={fields.customer_id ? arraySelect2(fields.customer_id) : ""}
																options={ {
																	placeholder: "Select customer"
																} }
																data={dataSelect2(customers)}
															/>
															: <FormText>
																<span className="text-mutted"><i>No <b>customers</b> available!</i></span>
															</FormText>}
													</FormGroup>
												</Col>
												</Row>
											<Row className="align-items-center">
												<Col className="mt-3">
													{data.alert ? 
														<Alert color={data.alert.type}>
															{data.alert.message }
														</Alert> : null 
													}
												</Col>
												<Col className="text-right" xs="4">
													<Button
														disabled={Object.keys(fields).length ? false : true}
														color="success"
														type="submit"
														onClick={(e) => this.handleSubmit(e)}
														size="lg"
													>
														Sauvegarder
													</Button>
												</Col>
											</Row>
										</div>
									</Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </> : <></>
    );
  }
}

export default CampaignsEdit;