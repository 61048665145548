import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	getIndex, dataSelect2, arraySelect2, getEdit,
	submitEdit,
	changeField,
} from "../../Utils/Req";

import {
	InputGroup, Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input, Container,
	Row,
	Col,
	FormText,
} from "reactstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import Header from "../../Components/Headers/Header";

class ResponsesEdit extends Component {

	state = {
		data: {},
		fields: {},
		posts: {},

		alert: {}
	}

	componentDidMount() {
		getEdit("responses", this.props.match.params.id).then(async (response) => {
			const resp = await response;
			this.setState({
				data: resp,
				fields: resp.response
			});
		})
		getIndex("posts", false).then(async (response) => {
			const resp = await response;
			this.setState({
				posts: resp.posts
			});
		})

	}

	handleSubmit = (e) => {
		e.preventDefault()
		const valid = document.getElementById('from-responses').reportValidity()
		if (valid) {
			const ctrl = {
				url: "responses",
				field: "response"
			}
			submitEdit(ctrl, this.state.fields, this.props.match.params.id).then(async (response) => {
				const resp = await response;
				this.setState({
					data: resp,
					fields: resp.response,
					alert: resp.alert
				});
			})
		}
	}

	handleChangeField = (e) => {
		let fields = this.state.fields
		return this.setState({ fields: changeField(e, fields) });
	}

	render() {
		let fields = this.state.fields
		let data = this.state.data
		let posts = this.state.posts

		return (
			Object.keys(fields).length ? <>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Responses</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Link
												color="warning"
												className="btn btn-warning btn-sm"
												to={`/responses`}
												size="sm"
											>
												Retour
                      </Link>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form autoComplete="off" id="from-responses" method="post">
										<h6 className="heading-small text-muted mb-4">
											Edit response
										</h6>
										<div className="pl-lg-4">
											<Row>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-date"
														>
															Date
														</label>
														<InputGroup className="input-group-alternative">
															<ReactDatetime
																closeOnSelect={true}
																timeFormat={false}
																onChange={(e) => this.handleChangeField({ value: e, type: "text", name: "date" })}
																inputProps={{
																	name: "date",
																	required: true
																}}
																dateFormat={"YYYY-MM-DDTHH:mm:ssZ"}
																value={fields.date ? moment(fields.date).format("YYYY") === "0001" ? null : moment(fields.date) : null}
															/>
														</InputGroup>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-description"
														>
															Description
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.description ? fields.description : ""}
															name={`description`}
															className="form-control-alternative"
															id="input-description"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-value"
														>
															Value
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.value ? fields.value : ""}
															name={`value`}
															className="form-control-alternative"
															id="input-value"
															type="text"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-post_id"
														>
															post
														</label>
														{posts.length ?
															<Select2
																name={`post_id`}
																onSelect={(e) => this.handleChangeField(e.target)}
																onUnselect={(e) => this.handleChangeField(e.target)}
																defaultValue={fields.post_id ? arraySelect2(fields.post_id) : ""}
																options={{
																	placeholder: "Select post"
																}}
																data={dataSelect2(posts)}
															/>
															: <FormText>
																<span className="text-mutted"><i>No <b>posts</b> available!</i></span>
															</FormText>}
													</FormGroup>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col className="mt-3">
													{data.alert ?
														<Alert color={data.alert.type}>
															{data.alert.message}
														</Alert> : null
													}
												</Col>
												<Col className="text-right" xs="4">
													<Button
														disabled={Object.keys(fields).length ? false : true}
														color="success"
														type="submit"
														onClick={(e) => this.handleSubmit(e)}
														size="lg"
													>
														Sauvegarder
													</Button>
												</Col>
											</Row>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</> : <></>
		);
	}
}

export default ResponsesEdit;