import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	getEdit,
	submitEdit,
	changeField,
} from "../../Utils/Req";

import {
	Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input, Container,
	Row,
	Col,
} from "reactstrap";
import Header from "../../Components/Headers/Header";

class UsersEdit extends Component {

	state = {
		data: {},
		fields: {},

		alert: {}
	}

	componentDidMount() {
		getEdit("users", this.props.match.params.id).then(async (response) => {
			const resp = await response;
			this.setState({
				data: resp,
				fields: resp.user
			});
		})

	}

	handleSubmit = (e) => {
		e.preventDefault()
		const valid = document.getElementById('from-users').reportValidity()
		if (valid) {
			const ctrl = {
				url: "users",
				field: "user"
			}
			submitEdit(ctrl, this.state.fields, this.props.match.params.id).then(async (response) => {
				const resp = await response;
				this.setState({
					data: resp,
					fields: resp.user,
					alert: resp.alert
				});
			})
		}
	}

	handleChangeField = (e) => {
		let fields = this.state.fields
		return this.setState({ fields: changeField(e, fields) });
	}

	render() {
		let fields = this.state.fields
		let data = this.state.data

		return (
			Object.keys(fields).length ? <>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Users</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Link
												color="warning"
												className="btn btn-warning btn-sm"
												to={`/users`}
												size="sm"
											>
												Retour
                      </Link>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form autoComplete="off" id="from-users" method="post">
										<h6 className="heading-small text-muted mb-4">
											Edit user
										</h6>
										<div className="pl-lg-4">
											<Row>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Name
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.name ? fields.name : ""}
															name={`name`}
															className="form-control-alternative"
															id="input-name"
															type="text"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-email"
														>
															Email
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.email ? fields.email : ""}
															name={`email`}
															className="form-control-alternative"
															id="input-email"
															type="email"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-phone"
														>
															Phone
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.phone ? `0${fields.phone}` : ""}
															name={`phone`}
															className="form-control-alternative"
															id="input-phone"
															type="tel"
															pattern="^(?:0)[1-9](?:\d\d){4}$"
															required={false}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-password"
														>
															Password
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.password ? fields.password : null}
															name={`password`}
															className="form-control-alternative"
															id="input-password"
															type="password"
															required={true}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-token"
														>
															Token
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.token ? fields.token : null}
															name={`token`}
															className="form-control-alternative"
															id="input-token"
															type="password"
															required={false}
														/>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-role"
														>
															Role
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.role ? fields.role : ""}
															name={`role`}
															className="form-control-alternative"
															id="input-role"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col className="mt-3">
													{data.alert ?
														<Alert color={data.alert.type}>
															{data.alert.message}
														</Alert> : null
													}
												</Col>
												<Col className="text-right" xs="4">
													<Button
														disabled={Object.keys(fields).length ? false : true}
														color="success"
														type="submit"
														onClick={(e) => this.handleSubmit(e)}
														size="lg"
													>
														Sauvegarder
													</Button>
												</Col>
											</Row>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</> : <></>
		);
	}
}

export default UsersEdit;