import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getIndex, deleteItem, dataSelect2 } from "../../Utils/Req";
import Select2 from "react-select2-wrapper";

import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Table,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Badge
} from "reactstrap";

import Header from "../../Components/Headers/Header";
import Paginator from "../../Components/Paginator/Paginator";
import { Config } from "../../Utils/Config";

class PostsIndex extends Component {

  state = {
    data: {
      records: []
    },
    send: false,
    post: null,
    campaigns: [],
    campaign: null
  }

  componentDidMount() {
    getIndex('posts').then(async (response) => {
      const resp = await response;
      this.setState({
        data: resp.posts,
      });
    })

    getIndex('campaigns', false).then(async (response) => {
      const resp = await response;
      this.setState({
        campaigns: resp.campaigns,
      });
    })
  }

  paginateData = (data) => {
    this.setState({
      data: data
    })
  }

  handleDelete = (k, id) => {
    let data = this.state.data;
    data.records.splice(k, 1)
    deleteItem("posts", id).then(async (response) => {
      await response;
      this.setState({
        data: data,
      });
    })
  }

  handleChangeField = async (e) => {
    let form = new FormData()
    form.append("campaign_id", e.value)

    let response = await fetch(`${Config.url}/filter-campaign`, {
      method: 'POST',
      headers: Config.headersUpload,
      body: form
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();

    this.setState({
      data: body.posts,
    });
  }

  handleSendMail = async (id) => {

    let form = new FormData()
    form.append('id', id);

    let response = await fetch(`${Config.url}/send-mail`, {
      method: 'POST',
      headers: Config.headersUpload,
      body: form
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();

    if (body.alert.type === "danger") {
      alert(body.alert.message)
    } else {

      const posts = this.state.data
      const post = this.state.data.records.findIndex(post => post.id === id);
      posts.records[post].state = "info"
      return this.setState({ data: posts, send: true, post: body.post })
    }
    return
  }

  filterBy = async (key, value) => {
    try {
      let response = await fetch(`${Config.url}/posts?page=1&limit=10&${key}=${value}`, {
        headers: Config.headers,
      })
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const body = await response.json();
      this.setState({
        data: body.posts,
      });
    } catch (err) {
      console.log(err)      
    }
  }


  render() {
    let { send, post, campaigns } = this.state
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6" md="2">
                      <h3 className="mb-0">Publications</h3>
                    </Col>
                    <Col xs="8" md="8">
                      {campaigns.length &&
                        <Select2
                          name={`campaign_id`}
                          onSelect={(e) => this.handleChangeField(e.target)}
                          onUnselect={(e) => this.handleChangeField(e.target)}
                          options={{
                            placeholder: "Select"
                          }}
                          data={dataSelect2(campaigns)}
                        />
                      }
                    </Col>
                    <Col className="text-right" xs="4" md="2">
                      <Link
                        color="primary"
                        className="btn btn-primary btn-sm"
                        to={`/posts/add`}
                        size="sm"
                      >
                        Ajouter
                      </Link>
                    </Col>
                    <Col className="text-center" xs="12" md="12">
                      <p></p>
                      <Badge color="info" pill className="pointer" onClick={() => this.filterBy("state", "info")}>Envoyé</Badge>&nbsp;
                      <Badge color="success" pill className="pointer" onClick={() => this.filterBy("state", "success")}>Validé</Badge>&nbsp;
                      <Badge color="warning" pill className="pointer" onClick={() => this.filterBy("state", "warning")}>Modifié</Badge>&nbsp;
                      <Badge color="danger" pill className="pointer" onClick={() => this.filterBy("state", "danger")}>Annulé</Badge>
                    </Col>

                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Mail</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Prévisualisation</th>
                      <th scope="col" width="30%">Remarques</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.records.map((d, k) => {
                      return (
                        <tr key={k} className={d.state && `table-${d.state}`}>
                          <th scope="col">
                            <Button onClick={() => window.confirm("Voulez-vous vraiment envoyer un e-mail avec cette publication au client maintenant?") ? this.handleSendMail(d.id) : null} color="secondary">
                              <i className="ni ni-email-83"></i>
                            </Button>
                          </th>
                          <th scope="col">{d.name}</th>
                          <th scope="col">{d.cover && <a target="_blank" rel="noopener noreferrer" href={`${Config.base}${d.cover}`}>
                            <img width="80" alt={d.name} src={`${Config.base}${d.cover}`} />
                          </a>}</th>
                          <th scope="col" className="text-center">{d.review && <textarea rows="5" className="form-control" readOnly>{d.review}</textarea>}</th>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#!"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <Link
                                  className="dropdown-item"
                                  to={`/posts/edit/${d.id}`}
                                >
                                  Modifier
                                </Link>
                                <DropdownItem
                                  onClick={() => window.confirm("Are you sure you want to delete this item ?") ? this.handleDelete(k, d.id) : null}
                                >
                                  Supprimer
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <Row>
                    <Col>
                      {send && <Alert className="alert-success">
                        Email envoyé pour la publication : <b>{post.name}</b>!
                      </Alert>}
                    </Col>
                    <Col>
                      {this.state.data.total_record ? <Paginator paginateData={this.paginateData.bind(this)} paginate={this.state.data} entity={`posts`} limit="5" /> : null}
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default PostsIndex;
