import CustomersRoutes from "./Customers"
import CampaignsRoutes from "./Campaigns"
import PostsRoutes from "./Posts"
import PostsIndex from "../Screens/Posts/index";
import UsersRoutes from "./Users"
import ResponsesRoutes from "./Responses"
const Routes = [
	[
		{
			path: "/",
			name: "Posts",
			exact: true,
			icon: "ni ni-folder-17 text-primary",
			component: PostsIndex,
			layout: "admin",
			sidebar: false
		}
	],
	CustomersRoutes,
	CampaignsRoutes,
	PostsRoutes,
	UsersRoutes,
	ResponsesRoutes,
]

export default Routes