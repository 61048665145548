import PostsIndex from "../Screens/Posts/index";
import PostsAdd from "../Screens/Posts/add";
import PostsEdit from "../Screens/Posts/edit";
const PostsRoutes = [  
	{
		path: "/posts",
		name: "Publications",
		exact: true,
		icon: "fas fa-chart-bar text-primary",
		component: PostsIndex,
		layout: "admin",
		sidebar: true
	},
	{
		path: "/posts/add",
		name: "Posts Add",
		exact: true,
		icon: "fas fa-chart-bar text-primary",
		component: PostsAdd,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/posts/edit/:id",
		name: "Posts Edit",
		exact: false,
		icon: "fas fa-chart-bar text-primary",
		component: PostsEdit,
		layout: "admin",
		sidebar: false
	}]

export default PostsRoutes