import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getIndex, deleteItem } from "../../Utils/Req";

import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import Header from "../../Components/Headers/Header";
import Paginator from "../../Components/Paginator/Paginator";

class UsersIndex extends Component {

  state = {
    data: {
      records: []
    }
  }

  componentDidMount() {
    getIndex('users').then(async (response) => {
      const resp = await response;
      this.setState({
        data: resp.users,
      });
    })
  }

  paginateData = (data) => {
    this.setState({
      data: data
    })
  }

  handleDelete = (k, id) => {
    let data = this.state.data;
    data.records.splice(k, 1)
    deleteItem("users", id).then(async (response) => {
      await response;
      this.setState({
        data: data,
      });
    })
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow"><CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Users</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link
                      color="primary"
                      className="btn btn-primary btn-sm"
                      to={`/users/add`}
                      size="sm"
                    >
                      Ajouter
										</Link>
                  </Col>
                </Row>
              </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Password</th>
                      <th scope="col">Token</th>
                      <th scope="col">Role</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.records.map((d, k) => {
                      return (
                        <tr key={k}>
                          <th scope="col">{d.name}</th>
                          <th scope="col">
                            <a href={`mailto:${d.email}`}>{d.email}</a>
                          </th>
                          <th scope="col">
                            <a href={`tel:0${d.phone}`}>0{d.phone}</a>
                          </th>
                          <th scope="col">{d.password}</th>
                          <th scope="col">{d.token}</th>
                          <th scope="col">{d.role}</th>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#!"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <Link
                                  className="dropdown-item"
                                  to={`/users/edit/${d.id}`}
                                >
                                  Modifer
                                </Link>
                                <DropdownItem
                                  onClick={() => window.confirm("Are you sure you want to delete this item ?") ? this.handleDelete(k, d.id) : null}
                                >
                                  Supprimer
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {this.state.data.total_record ? <Paginator paginateData={this.paginateData.bind(this)} paginate={this.state.data} entity={`users`} limit="5" /> : null}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default UsersIndex;
