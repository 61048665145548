import React from "react";
import { Config } from "../../Utils/Config";
// reactstrap components
import { Container, Row, Col, CardBody, CardTitle, Card } from "reactstrap";
class Header extends React.Component {
  
  state = {
    nsCustomers:0,
    nbPosts:0,
    nbCampaigns:0
  }

  componentDidMount() {
    this.handleHeader()
  }

  handleHeader = async () => {
    let response = await fetch(`${Config.url}/header`, {
      method: 'GET',
      headers: Config.headers
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    this.setState(body)
  }
  
  render() {
    let {nsCustomers, nbPosts, nbCampaigns} = this.state
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Clients
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {nsCustomers}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Campagnes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {nbCampaigns}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Publications
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">{nbPosts}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
