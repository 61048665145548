import React from 'react';
import { Config } from "./Config";
import { useDropzone } from 'react-dropzone';

export const DropzoneSingle = (props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,audio/*,video/*,.pdf',
    name: 'file',
    multiple: false,
    onDrop: acceptedFiles => {
      let text = getRootProps().ref.current.children[1]
      text.classList.add("text-warning")
      var data = new FormData()
      acceptedFiles.map(file => data.append('file', file, file.name));
      uploadFile(data).then((resp) => {
        props.handleChangeField({ type: "text", value: resp.file.url, name: props.name })
        text.classList.remove("text-warning")
        text.classList.add("text-success")
      })
    }
  });

  const files = acceptedFiles.map(file => {
    return file.path
  });

  return (
    <div className="dropzone dropzone-single mb-3 dz-clickable">
      <div {...getRootProps({ className: 'dz-preview dz-preview-single' })}>
        <input {...getInputProps()} />
        <p className="dz-default dz-message">
          {files.length ? files : "Faites glisser ou cliquez"}
        </p>
      </div>
    </div>
  );
}

const uploadFile = async (file) => {
  try {
    let response = await fetch(`${Config.url}/files/upload`, {
      method: 'POST',
      headers: Config.headersUpload,
      body: file
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    return body
  } catch (err) {
    console.log(err)
  }
}

export const getIndex = async (controler, paginate = true) => {
  try {
    let response = await fetch(`${Config.url}/${controler}${paginate ? `?page=1&limit=10` : ``}`, {
      headers: Config.headers,
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    return body
  } catch (err) {
    console.log(err)
    let data = {
      [controler]: {}
    }
    data[controler] = { records: [] }
    return data
  }
}

export const getAdd = async (controler) => {
  try {
    let response = await fetch(`${Config.url}/${controler}/add`, {
      headers: Config.headers
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    return body
  } catch (err) {
    console.log(err)
    let data = {
      [controler]: {},
      alert: {
        type: "danger",
        message: err
      }
    }
    return data
  }
}

export const getEdit = async (controler, id) => {
  try {
    let response = await fetch(`${Config.url}/${controler}/edit/${id}`, {
      headers: Config.headers,
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    return body
  } catch (err) {
    return err
  }
}

export const submitAdd = async (controler, fields) => {
  try {
    let response = await fetch(`${Config.url}/${controler.url}/add`, {
      method: 'POST',
      headers: Config.headers,
      body: JSON.stringify(fields, replacer)
    })
    let body = await response.json();
    return body
  } catch (err) {
    return err
  }
}

export const submitEdit = async (controler, fields, id) => {
  try {
    let response = await fetch(`${Config.url}/${controler.url}/edit/${id}`, {
      method: 'PUT',
      headers: Config.headers,
      body: JSON.stringify(fields, replacer)
    })
    let body = await response.json();
    return body
  } catch (err) {
    return err
  }
}

export const submitLogin = async (fields) => {
  try {
    let response = await fetch(`${Config.auth}`, {
      method: 'POST',
      headers: Config.headers,
      body: JSON.stringify(fields, replacer)
    })
    let body = await response.json();
    return body
  } catch (err) {
    return err
  }
}

export const deleteItem = async (controler, id) => {
  try {
    let response = await fetch(`${Config.url}/${controler}/${id}`, {
      method: 'DELETE',
      headers: Config.headers
    })
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const body = await response.json();
    return body
  } catch (err) {
    console.log(err)
  }
}

export const changeField = (e, fields) => {
  if (e.type === "checkbox" || e.type === "radio") { e.value = e.checked }
  if (e.type === "select-multiple" && e.multiple) {
    let values = [];
    for (let i = 0, l = e.options.length; i < l; i++) {
      if (e.options[i].selected) {
        values.push({ id: e.options[i].value });
      }
    }
    fields[e.name] = values;
    return fields;
  }
  fields[e.name] = e.value
  return fields;
}

export const arraySelect2 = (data) => {
  if (typeof data == "string") {
    return data
  }
  let ids = []
  if (data._ids) {
    data._ids.map((i) => ids.push(i))
  } else {
    data.map((i) => ids.push(i.id))
  }
  return ids
}

export const dataSelect2 = (data) => {
  let d = []
  data.map((i, k) => d.push({
    id: i.id,
    text: i.displayname
  }))
  return d
}

const replacer = (key, value) => {
  return (value === "true") ? Boolean(true) :
    (value === "false") ? Boolean(false) :
      (Number(value)) ? Number(value) :
        value;
}