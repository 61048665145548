import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../Components/Navbars/AdminNavbar";
import AdminFooter from "../Components/Footers/AdminFooter";
import Sidebar from "../Components/Sidebar/Sidebar";
import { Config } from "../Utils/Config";

import routes from "../routes";

class Admin extends React.Component {
  
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    if (!localStorage.getItem("token")) {
      window.location.replace("/login");
    }
  }

  componentDidMount() {
    
    // this.isAuth()

    if (!localStorage.getItem("token")) {
      window.location.replace("/login");
    }
  }

  isAuth = async () => {
    let response = await fetch(`${Config.url}/is-auth`, {
      method: 'GET',
      headers: Config.headersUpload
    })
    const body = await response.json();
    this.setState(body)

    console.log(body)
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "admin") {
        return (
          <Route
            path={`${prop.path}`}
            exact={prop.exact}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/ed-logo.png"),
            imgAlt: "Manager"
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText="Manager"
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
