import React, { Component } from "react";
import { Config } from "../../Utils/Config";

import {
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

class Paginator extends Component {

  componentDidMount() {
    const page = window.location.hash ? window.location.hash.split('page')[1] : null
    this.changePage(page)
  }

  changePage = (number) => {
    fetch(`${Config.url}/${this.props.entity}?page=${number}&limit=10`, {
      headers: Config.headers
    }).then(async (response) => {
      const json = await response.json();
      this.props.paginateData(json[this.props.entity])
    });
  }

  paginator = () => {
    let paginateItem = []
    let paginate = this.props.paginate

    let maxPagination = paginate.total_page >= this.props.limit ? this.props.limit - 1 : paginate.total_page - 1
    let offset = paginate.page >= Math.ceil(this.props.limit / 2) ? Math.ceil(this.props.limit / 2 - 1) : paginate.page >= 2 ? 1 : 0

    let start = paginate.total_page - offset <= paginate.page
      ? (paginate.page - maxPagination + (paginate.total_page - paginate.page))
      : paginate.page > Math.ceil(this.props.limit / 2) - 1
        ? (paginate.page - offset)
        : 1

    let end = paginate.page > Math.ceil(this.props.limit / 2) - 1
      ? (paginate.page + maxPagination - offset)
      : this.props.limit

    for (let j = start; j <= paginate.total_page; j++) {
      if (j > end) {
        break
      }
      paginateItem.push(
        <PaginationItem key={j} className={paginate.page === j ? "active" : null}>
          <PaginationLink
            href={`#page${j}`}
            onClick={() => this.changePage(j)}
          >
            {j}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return paginateItem
  }

  render() {
    return (
      <nav aria-label="Pagination">
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={this.props.paginate.prev_page === this.props.paginate.page ? "disabled" : null}>
            <PaginationLink
              href={`#page${this.props.paginate.page - 1}`}
              onClick={() => this.changePage(this.props.paginate.page - 1)}
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>

          {this.paginator()}

          <PaginationItem className={this.props.paginate.next_page === this.props.paginate.page ? "disabled" : null}>
            <PaginationLink
              href={`#page${this.props.paginate.page + 1}`}
              onClick={() => this.changePage(this.props.paginate.page + 1)}
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
    )
  }
}

export default Paginator;