import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	getIndex, dataSelect2, arraySelect2, DropzoneSingle, getAdd,
	submitAdd,
	changeField,
} from "../../Utils/Req";

import {
	InputGroup, Alert,
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input, Container,
	Row,
	Col,
	FormText,
} from "reactstrap";

import moment from "moment";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import Header from "../../Components/Headers/Header";

import 'moment/locale/fr'

class PostsAdd extends Component {

	state = {
		data: {},
		fields: {},
		campaigns: {},

		alert: {}
	}

	componentDidMount() {
		getAdd("posts").then(async (response) => {
			const resp = await response;
			this.setState({
				data: resp,
				fields: resp.post
			});
		})

		getIndex("campaigns", false).then(async (response) => {
			const resp = await response;
			this.setState({
				campaigns: resp.campaigns
			});
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()
		const valid = document.getElementById("from-posts").reportValidity()
		if (valid) {
			const ctrl = {
				url: "posts",
				field: "post"
			}

			let data = this.state.fields
			data.name = `Publication du ${moment(data.start).format("DD MMMM")}`

			submitAdd(ctrl, this.state.fields).then(async (response) => {
				const resp = await response;
				this.setState({
					data: resp,
					fields: resp.post,
					alert: resp.alert
				});
			})
		}
	}

	handleChangeField = (e) => {
		let fields = this.state.fields
		return this.setState({ fields: changeField(e, fields) });
	}

	render() {
		let fields = this.state.fields
		let data = this.state.data
		let campaigns = this.state.campaigns

		return (
			<>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">
											<h3 className="mb-0">Publication</h3>
										</Col>
										<Col className="text-right" xs="4">
											<Link
												color="warning"
												className="btn btn-warning btn-sm"
												to={`/posts`}
												size="sm"
											>
												Retour
                      </Link>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Form autoComplete="off" id="from-posts" method="post">
										<h6 className="heading-small text-muted mb-4">
											Ajout d'une publication
                    </h6>
										<div className="pl-lg-4">
											<Row>

												{/* <Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-name"
														>
															Name
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.name ? fields.name : ""}
															name={`name`}
															className="form-control-alternative"
															id="input-name"
															type="text"
															required={true}
														/>
													</FormGroup>
												</Col> */}

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-cover"
														>
															Prévisualisation
														</label>
														<DropzoneSingle name={"cover"} handleChangeField={this.handleChangeField} />
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-description"
														>
															Description
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.description ? fields.description : ""}
															name={`description`}
															className="form-control-alternative"
															id="input-description"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col>
												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-link"
														>
															Lien de la publication
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.link ? fields.link : ""}
															name={`link`}
															className="form-control-alternative"
															id="input-link"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col>

												{/* <Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-review"
														>
															Review
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.review ? fields.review : ""}
															name={`review`}
															className="form-control-alternative"
															id="input-review"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col> */}

												{/* <Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-state"
														>
															State
														</label>
														<Input
															onChange={e => this.handleChangeField(e.target)}
															defaultValue={fields.state ? fields.state : "muted"}
															name={`state`}
															className="form-control-alternative"
															id="input-state"
															type="text"
															required={false}
														/>
													</FormGroup>
												</Col> */}

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-start"
														>
															Début
														</label>
														<InputGroup className="input-group-alternative">
															<ReactDatetime
															  locale="fr-fr" 
																closeOnSelect={true}
																timeFormat={false}
																onChange={(e) => this.handleChangeField({ value: e, type: "text", name: "start" })}
																inputProps={{
																	name: "start",
																	required: true
																}}
																dateFormat={"YYYY-MM-DD"}
																value={fields.start ? moment(fields.start).format("YYYY") === "0001" ? moment() : moment(fields.start) : null}
															/>
														</InputGroup>
													</FormGroup>
												</Col>

												<Col lg="6">
													<FormGroup>
														<label
															className="form-control-label"
															htmlFor="input-campaign_id"
														>
															Campagne
														</label>
														{campaigns.length ?
															<Select2
																name={`campaign_id`}
																onSelect={(e) => this.handleChangeField(e.target)}
																onUnselect={(e) => this.handleChangeField(e.target)}
																defaultValue={fields.campaign_id ? arraySelect2(fields.campaign_id) : ""}
																options={{
																	placeholder: "Sélectionnez une campagne"
																}}
																required
																data={dataSelect2(campaigns)}
															/>
															: <FormText>
																<span className="text-mutted"><i>No <b>campaigns</b> available!</i></span>
															</FormText>}
													</FormGroup>
												</Col>
											</Row>
											<Row className="align-items-center">
												<Col className="mt-3">
													{data.alert ?
														<Alert color={data.alert.type}>
															{data.alert.message}
														</Alert> : null
													}
												</Col>
												<Col className="text-right" xs="4">
													<Button
														disabled={Object.keys(fields).length ? false : true}
														color="success"
														type="submit"
														onClick={(e) => this.handleSubmit(e)}
														size="lg"
													>
														Sauvegarder
													</Button>
												</Col>
											</Row>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default PostsAdd;