import React from "react";
import { changeField, submitLogin } from "../../Utils/Req";

import {
	Alert,
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col
} from "reactstrap";

class Login extends React.Component {

	state = {
		fields: {},
		alert: null,
		token: localStorage.getItem("token")
	}

	componentDidUpdate() {
		if (localStorage.getItem("token")) {
			window.location.replace("/");
		}
	}

	handleChangeField = (e) => {
		const fields = this.state.fields
		return this.setState({ fields: changeField(e, fields) });
	}

	handleLogin = (e) => {
		e.preventDefault()
		const valid = document.getElementById('from-users').reportValidity()
		if (valid) {
			submitLogin(this.state.fields).then(async (response) => {
				const resp = await response;				
				if (undefined !== resp.token) {
					this.storeToken(resp.token)
					this.setState({
						alert: resp.alert,
						token: resp.token
					});
				} else {
					this.setState({
						alert: resp.alert
					});
				}
			})
		}
	}

	storeToken = (token) => {
		localStorage.setItem("token", token);
	}

	render() {
		return (
			<Col lg="5" md="7">
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						{this.state.alert ?
							<Alert color={this.state.alert.type}>
								{this.state.alert.message}
							</Alert> : null
						}
						<Form role="form" id="from-users">
							<FormGroup className="">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input name="email" required onChange={e => this.handleChangeField(e.target)} placeholder="Email" type="email" />
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input name="password" required onChange={e => this.handleChangeField(e.target)} placeholder="Password" type="password" />
								</InputGroup>
							</FormGroup>
							<div className="text-center">
								<Button onClick={(e) => this.handleLogin(e)} className="mt-4" color="primary" type="button">
									Login
									</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
				<Row className="mt-3">
					<Col xs="6">
						<a
							className="text-light"
							href="#!"
							onClick={e => e.preventDefault()}
						>
							<small>Password lost?</small>
						</a>
					</Col>
				</Row>
			</Col>
		);
	}
}

export default Login;