import CampaignsIndex from "../Screens/Campaigns/index";
import CampaignsAdd from "../Screens/Campaigns/add";
import CampaignsEdit from "../Screens/Campaigns/edit";
const CampaignsRoutes = [  
	{
		path: "/campaigns",
		name: "Campagnes",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: CampaignsIndex,
		layout: "admin",
		sidebar: true
	},
	{
		path: "/campaigns/add",
		name: "Campaigns Add",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: CampaignsAdd,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/campaigns/edit/:id",
		name: "Campaigns Edit",
		exact: false,
		icon: "ni ni-folder-17 text-primary",
		component: CampaignsEdit,
		layout: "admin",
		sidebar: false
	}]

export default CampaignsRoutes