import CustomersIndex from "../Screens/Customers/index";
import CustomersAdd from "../Screens/Customers/add";
import CustomersEdit from "../Screens/Customers/edit";
const CustomersRoutes = [  
	{
		path: "/customers",
		name: "Clients",
		exact: true,
		icon: "fas fa-users text-primary",
		component: CustomersIndex,
		layout: "admin",
		sidebar: true
	},
	{
		path: "/customers/add",
		name: "Customers Add",
		exact: true,
		icon: "fas fa-users text-primary",
		component: CustomersAdd,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/customers/edit/:id",
		name: "Customers Edit",
		exact: false,
		icon: "fas fa-users text-primary",
		component: CustomersEdit,
		layout: "admin",
		sidebar: false
	}]

export default CustomersRoutes