import ResponsesIndex from "../Screens/Responses/index";
import ResponsesAdd from "../Screens/Responses/add";
import ResponsesEdit from "../Screens/Responses/edit";
const ResponsesRoutes = [  
	{
		path: "/responses",
		name: "Responses",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: ResponsesIndex,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/responses/add",
		name: "Responses Add",
		exact: true,
		icon: "ni ni-folder-17 text-primary",
		component: ResponsesAdd,
		layout: "admin",
		sidebar: false
	},
	{
		path: "/responses/edit/:id",
		name: "Responses Edit",
		exact: false,
		icon: "ni ni-folder-17 text-primary",
		component: ResponsesEdit,
		layout: "admin",
		sidebar: false
	}]

export default ResponsesRoutes